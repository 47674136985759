import React from 'react';
import styled from 'styled-components';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import { TextAndImage } from '@beelineloans/cx-library/src/components/layout/panels';
import Container from '@beelineloans/cx-library/src/components/layout/Container';
import { Breakpoint, Legal, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import { loginEndpoint } from '@beelineloans/cx-library/src/utils/helpers';
import { Button } from '@beelineloans/cx-library/src/components/buttons';
import Beach from '../../../shared/images/cat-couch-full.png';

const LegalWrap = styled(Container)`
  .container-inner {
    padding-top: 0;
  }
`;

const Main = styled(TextAndImage)`
  .container-inner {
    padding-bottom: 75px;

    .image-wrap {
      align-self: center;
    }
  }
`;

const LegalText = styled(Legal)`
  padding-top: 30px;
`;

const ButtonWrap = styled.div`
  @media only screen and (${props => props.theme.mediaBreakpoints.small}) {
    text-align: center
  }
`;

const HeadlineSpan = styled.span`
  padding-right:20px;
  display: block;
`;

const headlineBlock = (
  <Breakpoint
    smallBreakpoint={(
      <>
        Lock your rate and have it both ways. Be protected if they go up and benefit if they go down.
      </>
    )}
    largeBreakpoint={(
      <HeadlineSpan>
        Lock your rate and have it both ways. Be protected if they go up and benefit if they go down.
      </HeadlineSpan>
    )}
  />
);

const RateFloatDown = () => (
  <Template headline={headlineBlock}>
    <SEO
      path="/ratefloatdown/"
      title="Lock then chill out — even if rates go down."
      description="In two minds about whether to lock your rate?"
    />
    <Main
      heading="In two minds about whether to lock your rate?"
      image={{
        src: Beach,
        mobileWidth: '315px',
        desktopWidth: '500px'
      }}
    >
      <Paragraph>
        What if you don’t lock, then rates go up? You’ll kick yourself.
      </Paragraph>
      <Paragraph>
        But what if you do lock and they go down? And you feel like a chump?
      </Paragraph>
      <Paragraph>
        No one wants to be a chump.
      </Paragraph>
      <Paragraph>
        So why not have it both ways?
      </Paragraph>
      <Paragraph>
        If you lock, but rates go down by more than 0.25%, reach out to your Loan Guide and they’ll drop the rate you locked by 0.125%.
      </Paragraph>
      <Paragraph>
        Even if you’re well on your way to closing, you can still drop it down right up to 10 days before your closing date.
      </Paragraph>
      <Paragraph>
        So lock your rate in, then sink deep into your sofa, knowing you’ve got it both ways, instead of worrying about rate movements — like a chump.
      </Paragraph>
    </Main>
    <LegalWrap>
      <ButtonWrap>
        <Button to={loginEndpoint}>Float my rate down</Button>
      </ButtonWrap>
      <LegalText>
        *To float down the rate you&apos;ve locked you first need to lock your rate with us, then rates have to be 0.25% lower than your locked rate.
        Then just ask your Loan Guide to drop your rate for you and they’ll drop it 0.125% below your locked rate. (only available once per loan)
      </LegalText>
    </LegalWrap>
  </Template>
);

export default RateFloatDown;
